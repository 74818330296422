import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { PAYMENT, VERIFY_FAIL } from './shared/Steps';
import ErrorMessage from './shared/ErrorMessage';

const VerifyTicket = ({ prevStep, nextStep, handleChange, values }) => {

  var { first_name, selected_ticket, meta, verifyTicketResult } = values

  const [fail, setFail] = useState();
  const [verifyTicketError, setVerifyTicketError] = useState();

  useEffect(() => {

    if (verifyTicketResult && !verifyTicketResult.result) {
        setVerifyTicketError(verifyTicketResult.message)
    } else if (verifyTicketResult.result) {
        nextStep(PAYMENT)
    } else {
        setVerifyTicketError('Something went wrong. Please try again later')
    }

  }, [verifyTicketResult])

  // for continue event listener
  const Continue = e => {
    if (e) {
      e.preventDefault();
    }

    nextStep(PAYMENT)
  }

  const Previous = e => {
    e.preventDefault();

    // Reset meta on back!
    //handleChange('meta', null )
    prevStep();
  }

  if (fail) {
    return <>
            <Header title="Confirm" first_name={first_name} />
            <ErrorMessage message={fail} />
           </>
  }

  if (verifyTicketError) {

    return <>
      <Header title="Confirm" first_name={first_name} />
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">

              <div className="alert alert-danger text-dark mt-2">
                <p>{verifyTicketError}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  }
 
  return (
    <>
        Loading...
    </>
  )
}

export default VerifyTicket
