import Countdown from "./Countdown";
import React, { useState, useEffect } from 'react';
import DateRangeFormat from './DateRangeFormat';

const moment = require('moment');
require('moment-timezone');

const EventCard = ({ event, handleChange, values, checkbox, count, markInactive, eventId, handleEventUpdate }) => {
  var { selected_ticket, selected_occurrence, ticket, meta, organization_details } = values

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!selected_ticket) {
        updateActiveTicket();
      }
    }, 1000); // Run the updateActiveTicket function every second

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  const updateActiveTicket = () => {
    // Get the current date and time
    const stockholmTime = moment().tz('Europe/Stockholm');
    const now = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    // Create a new array of occurrences with the updated is_active property
    const updatedOccurrences = event.organization_event_occurrences.map((occurrence) => {
      const updatedTickets = occurrence.tickets.map((ticket) => {
        // Compare the ticket's end date with the current date
        const ticketStartDate = ticket.start_date;
        const ticketEndDate = ticket.end_date;
        const isTicketActive = ticketEndDate >= now && now >= ticketStartDate;

        return { ...ticket, is_active: isTicketActive };
      });

      return { ...occurrence, tickets: updatedTickets };
    });

    // Create a new event object with the updated occurrences
    const updatedEvent = { ...event, organization_event_occurrences: updatedOccurrences };

    // Call the handleEventUpdate callback function to pass the updated event back to the Main component
    handleEventUpdate(updatedEvent);
  };


  const toggleLines = (id, e) => {
    e.preventDefault();
    var event = document.getElementById('event-' + id);

    var dots = event.getElementsByClassName("dots")[0];
    var moreText = event.getElementsByClassName("more")[0];
    var btnText = event.getElementsByClassName("myBtn")[0];
    var shortText = event.getElementsByClassName("short")[0];

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Show More";
      moreText.style.display = "none";
      shortText.style.display = "inline";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Show Less";
      moreText.style.display = "inline";
      shortText.style.display = "none";
    }
  }

  const getTicketMeta = (ticketId) => {
    if (meta && meta.tickets && meta.tickets.length > 0) {
      for (var ticketsMeta of meta.tickets) {
        if (ticketsMeta.id === ticketId) {
          return ticketsMeta
        }
      }
    }
    return null
  }

  const toPriceString = (priceInSmallestUnit, currency) => {
    return parseFloat(priceInSmallestUnit / 100).toFixed(2) + ' ' + currency
  }

  const getTotalPrice = (meta, price, currency) => {
    let priceInSmallestUnit = parseInt(meta.count) * price
    return toPriceString(priceInSmallestUnit, currency)
  }

  const renderTicket = (ticket, showCount, eventId, occurrenceId) => {
    let ticketsMeta = getTicketMeta(ticket.id)

    const stockholmTime = moment().tz('Europe/Stockholm');
    const stockholmTimeString = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';


    let upcomingTicket = ticket.start_date > stockholmTimeString

    return <label className="d-block px-0" htmlFor={ticket.id} key={ticket.id}><div className={'row align-items-center'} key={ticket.id}>
      <div className="col-6">
        <dl className="name mb-1">
          {/* <dt>Ticket</dt> */}
          <span className="mt-3 d-block"><strong>{ticket.name}</strong></span>
          {/* {ticket.price && <dt className="event-price">{toPriceString(ticket.price, ticket.currency)}</dt>} */}
          {/* {markInactive && !ticket.is_active && <dd>Closed</dd>} */}
          {/* {ticket.ticket_statistics && <dt>{ticket.ticket_statistics.count} <small>{ticket.ticket_statistics.count > 1 ? 'Tickets Left' : 'Ticket Left'}</small></dt>} */}

          {/* {ticket.ticket_statistics && ticket.ticket_statistics.count !== 0 && ticket.is_active &&
            <span className="col-12 upcoming">
              <Countdown countMessage={'Available for:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.end_date} onCountdownFinished={handleCountdownFinished} />
            </span>
          } */}
        </dl>
        <dl className="tickets d-flex">
          <div>
            <dt>Quantity</dt>
            <dd>{ticketsMeta.count}</dd>
          </div>
          <div className="mx-3">
            <dt>Total price <b>(Inc. VAT)</b></dt>
            <dd>{getTotalPrice(ticketsMeta, ticket.price, ticket.currency)}</dd>
          </div>
        </dl>
      </div>

      {/* <div className="col-4">
        {count && <dl className="tickets">
          <dt>Availability</dt>
          {<dd>{ticket.ticket_statistics.count} <small>{ticket.ticket_statistics.count > 1 ? 'Tickets Left' : 'Ticket Left'}</small></dd>}
        </dl>}
      </div> */}

      {/* <div className="col-4">
        <dl className="price">
          <dt>Ticket Price</dt>
          {ticket.price && <dd>{toPriceString(ticket.price, ticket.currency)}</dd>}
        </dl>
      </div> */}


      {/* {showCount &&
        <div className="col-6 d-flex justify-content-center">
          <dl className="tickets">
            <dt>Quantity</dt>
            <dd>{ticketsMeta.count}</dd>
            <dt>Total price</dt>
            <dd>{getTotalPrice(ticketsMeta, ticket.price, ticket.currency)}</dd>
          </dl></div>} */}


      {checkbox ?
        !ticket.is_active && upcomingTicket ?
          <span className="col-6 upcoming d-flex justify-content-center"><Countdown countMessage={'Available in:'} eventId={eventId} occurrenceId={occurrenceId} ticketId={ticket.id} startDate={ticket.start_date} onCountdownFinished={handleCountdownFinished} /></span>
          : !ticket.is_active ?
            <span className="col-6 d-flex justify-content-center">Passed</span>
            : ticket.ticket_statistics.count === 0 ?
              <span className="col-6 d-flex justify-content-center">Sold Out</span>
              :
              <div className="col-6 form-check d-flex justify-content-center">
                <input className="form-check-input" type="radio" name="selected_ticket" id={ticket.id}
                  value={ticket.id}
                  onChange={(e) => handleChange('selected_ticket', parseInt(e.target.value))}
                  checked={selected_ticket === ticket.id} />
              </div> : ''}
    </div>
    </label>
  }

  const renderOccurrenceAndTicket = (event, occurrence, ticket) => {
    return <>
      <div className="row" key={occurrence.id}>
        <div className="col-12 ticketSelect">

          <dl className="name mb-1">
            <span className="mt-3 d-block">
              <strong>
                {event.title && event.title}
              </strong>
            </span>
          </dl>


          <DateRangeFormat occurrence={occurrence} organization={organization_details} compactStyle={true} />
          {/* <dl> */}
          {/* <dd>{occurrence.name}</dd> */}
          {/* {markInactive && occurrence.date_has_passed && <span className="tag-passed"><dd>Passed</dd></span>} */}
          {/* </dl> */}
        </div>
      </div>

      {renderTicket(ticket, true)}
    </>
  }


  const handleCountdownFinished = (eventId, occurrenceId, ticketId) => {
    // Create a new array of occurrences with the updated is_active property
    const updatedOccurrences = event.organization_event_occurrences.map((occurrence) => {
      if (occurrence.id === occurrenceId) {
        const updatedTickets = occurrence.tickets.map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, is_active: true };
          }
          return ticket;
        });

        return { ...occurrence, tickets: updatedTickets };
      }

      return occurrence;
    });

    // Create a new event object with the updated occurrences
    const updatedEvent = { ...event, organization_event_occurrences: updatedOccurrences };

    // Call the handleEventUpdate callback function to pass the updated event back to the Main component
    handleEventUpdate(updatedEvent);
  };

  const renderOccurrences = (event, selected_occurrence, ticket) => {
    // render only the selected ticket / occurrence
    if (selected_occurrence && ticket) {
      return renderOccurrenceAndTicket(event, selected_occurrence, ticket)
    }

    // render all occurrence / tickets
    if (event && event.organization_event_occurrences && event.organization_event_occurrences.length > 0) {
      return event.organization_event_occurrences.map(function (occurrence, i) {

        let closedEvent = occurrence.date_has_passed ? '' : '';

        return <div className={'row mt-3 mb-3 event-ticket ' + closedEvent} key={i}>
          <div className="col-12">
            <DateRangeFormat occurrence={occurrence} organization={organization_details} />

            {/* {markInactive && occurrence.date_has_passed && <span className="tag-passed"><dd>Passed</dd></span>} */}

            {/* <dl> */}
            {/* <dt>Occurrence</dt> */}
            {/* <dd>{occurrence.name}</dd> */}
            {/* </dl> */}
          </div>

          {occurrence.tickets && occurrence.tickets.length > 0 && occurrence.tickets.map(ticket => {
            return renderTicket(ticket, false, event.id, occurrence.id)
          })}

        </div>
      })

    }

  }

  return (

    <>
      {event &&
        <div className="container membership">
          <div className="row">
            <div className="col-12 mx-auto my-3" id={'event-' + event.id}>
              <div className="mw-container mx-auto" htmlFor={event.id}>

                {/* {event && event.image_url && <img src={event.image_url} className="w-100" />} */}
                {/* <div className="card-header bg-white"> */}
                {/* {checkbox &&
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="selected_event" id={event.id}
                          value={event.id}
                          onChange={(e) => handleChange('selected_event', parseInt(e.target.value))}
                          checked={selected_event === event.id} />
                      </div>} */}
                <h6 className="mb-1"><strong>Your Booking Information</strong></h6>
                {/* <h5 className="card-title">{event.title && event.title}</h5> */}
                {/* <div className="d-flex justify-content-between align-items-baseline">
                    <div className="card-text mw-100" dangerouslySetInnerHTML={{
                      __html: event.content && !eventId && !selected_ticket &&
                        event.content.length > 180 ?
                        "<div className='short'>" + event.content.substring(0, 210) + "<span className='dots'>...</span></div>"
                        // "<div className='more'>" + event.content + '</div>'
                        : event.content
                    }} />
                     <dl className="price">
                        <dt>Price</dt>
                        {event.price && <dd>{parseFloat(event.price / 100).toFixed(2)}{' '}{event.currency}</dd>}
                      </dl>
                  </div> */}

                {/* {event.content && event.content.length > 180 ?
                    <a href='/#' className="btn btn-outline-primary btn-sm myBtn" onClick={(e) => toggleLines(event.id, e)} >Show More</a>
                    :
                    ''} */}

                {/* </div> */}

                <div className="card">
                  <div className="card-footer">
                    {!eventId && !selected_ticket ?
                      <a href={'/' + event.organization_id + '/events/' + event.id} className="btn btn-primary w-100" >View Tickets</a>
                      :
                      renderOccurrences(event, selected_occurrence, ticket)
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      }
    </>
  )

}

export default EventCard