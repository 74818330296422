import React, { useEffect, useState } from "react";

export default function CheckoutForm({ transactionId }) {
  let paymentId = transactionId
  const [iframeMounted, setIframeMounted] = useState(false);

  useEffect(() => {
    if (!iframeMounted) {
      const handlePaymentCompleted = (response) => {
        window.location = '/success?payment_intent=' + paymentId
      };

      const initializeCheckout = () => {
        const checkoutOptions = {
          checkoutKey: 'test-checkout-key-a5b93cb02b1b4597be2800fbec34636d',
          paymentId: paymentId,
          containerId: "checkout-container-div",
        };

        const checkout = new window.Dibs.Checkout(checkoutOptions);
        checkout.on('payment-completed', handlePaymentCompleted);
      };

      if (paymentId) {
        // Payment ID is available, initialize the checkout
        initializeCheckout();
        setIframeMounted(true);
      } else {
        console.log("Expected a paymentId");
        // No paymentId provided, handle accordingly (redirect or show an error)
        // For example, you might want to redirect to the cart page
      }
    }
  }, [paymentId, iframeMounted])


  return (
    <div id="checkout-container-div"></div>
  );
}

